// 教师管理排课信息
<template>
  <div class="custom_table_with_time">
    <div class="date_picker">
      <!-- 学情推送时间 -->
      <span>
        <el-date-picker v-model="classDate" type="date" placeholder="选择日期" size="small" @change="pickerDate" value-format="yyyy-MM-dd"> </el-date-picker>
      </span>
    </div>
    <!--  stripe 斑马纹 -->
    <el-table style="98%" border :data="tableArr" :cell-style="{ padding: '0px' }" :row-style="{ height: '60px' }" :max-height="maxHeight">
      <el-table-column prop="time_range" align="center" label="时段" min-width="12.5%"></el-table-column>

      <!-- 天的数据  采用自定义表头方式来实现 -->
      <el-table-column v-for="(day, index) in headArr" :key="index" align="center" min-width="12.5%">
        <!-- 自定义表头 -->
        <template #header>
          <div>{{ headArr[index].weekDay }}</div>
          <div>{{ headArr[index].date }}</div>
        </template>

        <!-- 自定义表格内容 -->
        <template slot-scope="scope">
          <div class="custom_tab_content">
            <div
              :class="[
                'item_table',
                obj.class_state == 1 ? 'has_bg' : 'no_bg',
                {
                  has_bom: pos != scope.row.dayList[index].planMap.length - 1,
                },
              ]"
              v-for="(obj, pos) in scope.row.dayList[index].planMap"
              :key="pos"
            >
              <div class="margin_top_4">教室：{{ obj.room_name }}</div>
              <div>班级：{{ obj.class_name }}</div>
              <div>课程：{{ obj.sub_name }}</div>
              <div>
                <span>班级人数：</span>
                <span :class="[obj.class_num == obj.plan_num ? 'red_text' : 'green_text']">{{ obj.class_num }}</span
                >/{{ obj.plan_num }}人
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 标识部分 -->
    <div class="bom_info">
      <div class="flex_1"></div>
      <div class="com_dv color_1 margin_right_10"></div>
      <div>已排已上</div>
      <div class="com_dv color_2 margin_left_30 margin_right_10"></div>
      <div>已排未上</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      classDate: this.getNowTime(),
      //表头的数据

      headArr: [],
      //表格数据
      tableArr: [],

      //表给的最大高度
      maxHeight: 0,
    };
  },

  created() {
    //初始化表格的最大高度
    this.initHeihgt();
  },
  mounted() {
    this.httpData();
  },
  methods: {
    //初始化表格
    initHeihgt() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 76 - 50 - 50 - 150;
    },

    /**
     *  排课信息接口
     */
    httpData() {
      let param = this.getHttpParams();
      param.type = 11;
      param.tid = this.tid;
      param.date = this.classDate;

      this.$http
        .fetchPost(this.$api.TEACHERMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            this.tableArr = resulte.data;

            this.headArr = this.tableArr[0].dayList;
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },
    //处理默认选中当前日期
    getNowTime() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;
      console.log(defaultDate);
      return defaultDate;
    },
    //选择时间
    pickerDate() {
      if (this.classDate != null) {
        this.httpData();
      }
    },
  },
  props: ["tid"],
};
</script>

<style lang="scss" scoped>
.custom_table_with_time {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .date_picker {
    margin: 10px 0 10px 0;
  }

  ::v-deep .el-table .cell {
    padding: 0;
    line-height: 32px;
  }
  ::v-deep .el-table .el-table__cell {
    padding: 0;
  }

  //自定义的表格内容
  .custom_tab_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    .item_table {
      display: flex;
      flex-direction: column;
      padding: 0 4px;

      div {
        text-align: left;
        margin-left: 4px;
      }
    }

    //底边距
    .has_bom {
      margin-bottom: 10px;
    }
    .has_bg {
      background: #b3e6ff;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    //未排的背景色
    .no_bg {
      background: #fec433;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  //底部的排课信息展示区域
  .bom_info {
    width: 100%;
    display: flex;
    margin-top: 30px;
    align-items: center;

    .com_dv {
      width: 20px;
      height: 20px;
      border-radius: 4px;
    }

    .color_1 {
      background: #b3e6ff;
    }

    .color_2 {
      background: #fec433;
    }

    .color_3 {
      background: #cfd2cf;
    }
  }
}
</style>
