//查看教师信息
<template>
  <div class="look_teahcer">
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="look_teahcer_content">
          <!-- 教师姓名手机号 -->
          <div class="margin_top_20">
            <span>教师姓名：{{ teacherName }}</span>
            <span class="margin_left_40">手机号：{{ tel }}</span>

            <span v-show="isShowChangeTeacher" class="bt_change_teacher margin_left_40" @click="changeTeacher">换老师</span>
            <span class="margin_left_40">所在校区：{{ schoolName }}</span>

            <span class="margin_left_40">校区编码：{{ schoolId }}</span>
          </div>

          <!-- 所在校区 -->
          <div class="margin_top_20">
            <span>已开通课程权限：{{ course }}</span>
          </div>

          <!-- 切换按钮 -->
          <div class="action">
            <div class="margin_top_20">
              <el-radio-group v-model="radio" size="small" @change="doAction">
                <el-radio-button label="班级"></el-radio-button>
                <el-radio-button label="学员"></el-radio-button>
                <el-radio-button label="排课信息"></el-radio-button>
                <el-radio-button label="课消记录"></el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <!-- 班级表格部分 -->
          <div class="dv_table_view_content" v-show="showClassPage == 1">
            <el-table style="width: 100%; margin: auto" border stripe :data="tableData" :max-height="maxHeight">
              <!-- 序号 -->
              <el-table-column type="index" label="序号" align="center" width="60">
                <template scope="scope">
                  <span>{{ (currPage - 1) * pageSize + scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <!-- 班级名称 -->
              <el-table-column prop="class_name" label="班级名称" width="160"></el-table-column>

              <!-- 班级id -->
              <el-table-column prop="class_id" label="班级id" width="120"></el-table-column>
              <!--班级类型（1正常班级 2试听班级）-->
              <el-table-column prop="class_id" label="班级类型" width="100">
                <template slot-scope="scope">
                  {{ scope.row.type == 1 ? "正式班" : "试听班" }}
                </template>
              </el-table-column>
              <!-- 班级开设课程  -->
              <el-table-column prop="sub_name" :show-overflow-tooltip="true" label="开设课程" width="220"></el-table-column>

              <!-- 实招/预招 -->
              <el-table-column prop="class_num,plan_num" label="实招/预招" width="100"
                ><template slot-scope="scope"> {{ scope.row.class_num }}/{{ scope.row.plan_num }} </template></el-table-column
              >

              <!-- 限人数 -->
              <el-table-column prop="limit_plan" label="限人数" width="90">
                <template slot-scope="scope">
                  {{ scope.row.limit_plan == 1 ? "是" : "否" }}
                </template>
              </el-table-column>

              <!-- 已上/已排 -->
              <el-table-column prop="attend_num,arrange_num" label="已上/已排" width="100">
                <template slot-scope="scope"> {{ scope.row.attend_num }}/{{ scope.row.arrange_num }} </template>
              </el-table-column>

              <!--  操作 -->
              <el-table-column label="操作" min-width="20%">
                <template slot-scope="scope">
                  <el-button @click="doLookAction(scope.row)" type="text" size="small">查看</el-button>
                  <el-button @click="doCopy(scope.row)" type="text" size="small" v-if="scope.row.studentPlat == 1">复制班级邀请码</el-button>

                  <el-popover placement="bottom-start" width="50px" trigger="click" :ref="`popover-${scope.$index}`">
                    <div style="margin-left: 5%">
                      <el-button type="text" size="small" @click="doReplaceTeacher(scope)"> 换老师</el-button>
                    </div>
                    <div style="margin-left: 5%">
                      <el-button type="text" size="small" @click="doEdit(scope)"> 编辑</el-button>
                    </div>

                    <div style="margin-left: 5%">
                      <el-button type="text" size="small" @click="doDel(scope)"> 删除</el-button>
                    </div>

                    <!-- <el-button
                      style="margin-left: 10px"
                      type="text"
                      size="small"
                      slot="reference"
                    >
                      更多</el-button
                    > -->
                  </el-popover>
                </template>
              </el-table-column>
            </el-table>

            <!-- 顶部的分页器部分 -->
            <div class="bom_page margin_bom_20" v-if="tableData && tableData.length > 0">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currPage"
                :page-sizes="pageSizeArr"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalSize"
              >
              </el-pagination>
            </div>
          </div>

          <!-- 学员 -->
          <!-- 学员表格部分 -->
          <div class="dv_table_view_content" v-if="showClassPage == 2">
            <el-table style="width: 100%; margin: auto" border stripe :data="stableData" :max-height="maxHeight">
              <!-- 学生姓名 -->
              <el-table-column prop="name" label="学生姓名" width="100"></el-table-column>

              <!-- 学生id -->
              <el-table-column prop="id" label="学生id" :show-overflow-tooltip="true" width="120"></el-table-column>

              <!-- 手机号  -->
              <el-table-column prop="tel" :show-overflow-tooltip="true" label="手机号" width="120"></el-table-column>

              <!-- 所在班级 -->
              <el-table-column prop="class_name" label="所在班级" :show-overflow-tooltip="true" width="150"></el-table-column>

              <!--班级类型（1正常班级 2试听班级）-->
              <el-table-column prop="class_id" label="班级类型" width="80">
                <template slot-scope="scope">
                  {{ scope.row.type == 1 ? "正式班" : "试听班" }}
                </template>
              </el-table-column>
              <!-- 班级课程 -->
              <el-table-column prop="sub_name" label="班级课程" :show-overflow-tooltip="true" width="180"></el-table-column>

              <!-- 剩余课时/总课时 -->
              <el-table-column prop="balance_hour,total_hour" label="剩余课时/总课时" width="130">
                <template slot-scope="scope"> {{ scope.row.balance_hour }}/{{ scope.row.total_hour }} </template>
              </el-table-column>
              <!-- 实际出勤/应出勤次数 -->
              <el-table-column prop="class_attend_num,student_attend_num" label="实际出勤/应出勤次数" width="160">
                <template slot-scope="scope"> {{ scope.row.class_attend_num }}/{{ scope.row.student_attend_num }} </template>
              </el-table-column>

              <!--  操作 -->
              <el-table-column label="操作" min-width="20%">
                <template slot-scope="scope">
                  <el-button @click="doLookStudent(scope.row)" type="text" size="small">查看</el-button>
                </template>
              </el-table-column>
            </el-table>

            <!-- 顶部的分页器部分 -->
            <div class="bom_page margin_bom_20" v-if="tableData && tableData.length > 0">
              <el-pagination
                background
                @size-change="shandleSizeChange"
                @current-change="shandleCurrentChange"
                :current-page="scurrPage"
                :page-sizes="pageSizeArr"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="stotalSize"
              >
              </el-pagination>
            </div>
          </div>

          <!-- 课消记录部分 -->
          <div class="dv_table_view_content" v-if="showClassPage == 4">
            <div class="lineHeight">
              <span>上课日期：</span>
              <el-date-picker
                v-model="selectDate"
                type="daterange"
                range-separator="至"
                size="small"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="clickDate()"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
            <el-table style="width: 100%; margin: auto" border stripe :data="ctableData" :max-height="maxHeight">
              <!-- 上课时间 -->
              <el-table-column prop="class_date" label="上课时间" align="center" width="250">
                <template slot-scope="scope">
                  {{ scope.row.class_date + " " + scope.row.week_name + " " + scope.row.time_range }}
                </template>
              </el-table-column>

              <!-- 上课班级 -->
              <el-table-column prop="class_name" label="上课班级" :show-overflow-tooltip="true" width="200"></el-table-column>

              <!--班级类型（1正常班级 2试听班级）-->
              <el-table-column prop="class_id" label="班级类型" width="160">
                <template slot-scope="scope">
                  {{ scope.row.type == 1 ? "正式班" : "试听班" }}
                </template>
              </el-table-column>
              <!-- 上课教室 -->
              <el-table-column prop="room_name" label="上课教室" width="180"></el-table-column>

              <!-- 班级课程  -->
              <el-table-column prop="sub_name" :show-overflow-tooltip="true" label="班级课程"></el-table-column>

              <!-- 班级实际出勤学员 -->
              <el-table-column prop="attend_num" label="班级实际出勤学员" width="180"></el-table-column>
            </el-table>

            <!-- 顶部的分页器部分 -->
            <div class="bom_page margin_bom_10" v-if="ctableData && ctableData.length > 0">
              <el-pagination
                background
                @size-change="chandleSizeChange"
                @current-change="chandleCurrentChange"
                :current-page="ccurrPage"
                :page-sizes="pageSizeArr"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="ctotalSize"
              >
              </el-pagination>
            </div>
          </div>

          <!-- 空视图部分 -->
          <!-- <div class="show_content" v-show="isShowEmptyView">
            <EmptyView></EmptyView>
          </div> -->
          <!-- 排课信息 -->
          <CourseArrangement :tid="tid" v-if="showClassPage == 3"></CourseArrangement>

          <!-- 替换老师的dialog -->
          <!-- <ReplaceTeacherDialog
             v-if="isShowReplaceTeacherDialog"
            @close="isShowReplaceTeacherDialog = false"
            :isCenter="true"
            :showDialog="isShowReplaceTeacherDialog"
            :width="LookClassDialogWidth"
            title="提示"
          ></ReplaceTeacherDialog> -->

          <!-- 禁用的弹框 -->
          <RemoveClassDialog
            ref="del_item"
            v-if="showDelDialog"
            :showDialog="showDelDialog"
            @close="showDelDialog = false"
            :title="delTitle"
            @delItem="delItem"
          ></RemoveClassDialog>

          <!-- 编辑班级 -->
          <EditClassDialog
            v-if="showEditClassrDialog"
            @close="closeDialog"
            :title="editTitle"
            :showDialog="showEditClassrDialog"
            :item="classItem"
          ></EditClassDialog>
        </div>

        <!-- 替换老师的dialog -->
        <ReplaceTeacherDialog
          v-if="isShowReplaceTeacherDialog"
          @updatePage="updatePage"
          @close="isShowReplaceTeacherDialog = false"
          :isCenter="isCenter"
          :showDialog="isShowReplaceTeacherDialog"
          :width="repalceTeacherDialogWidth"
          title="提示"
          :item="teacher_item"
        ></ReplaceTeacherDialog>
      </template>
    </Layout>
  </div>
</template>
<script>
import CourseArrangement from "@/views/educationalcenter/teachermanager/CourseArrangement";

//替换老师的dialog
import ReplaceTeacherDialog from "@/components/dialog/ReplaceTeacherDialog.vue";
//移出班级的弹框
import RemoveClassDialog from "@/components/dialog/RemoveClassDialog";

//编辑班级的弹框
import EditClassDialog from "@/components/dialog/EditClassDialog";

export default {
  data() {
    return {
      //替换老师弹框的宽度
      repalceTeacherDialogWidth: "520px",
      isShowChangeTeacher: true,
      teacher_item: null,
      //老师姓名
      teacherName: "",
      //手机号
      tel: "",
      //学校校区
      schoolName: "",
      //学校ID
      schoolId: "",
      //开通课程
      course: "",
      //老师id
      tid: -1,
      //禁用标题
      delTitle: "删除班级",

      editTitle: "编辑班级",
      //显示班级/学员
      showClassPage: 1,
      radio: "班级",
      //是否显示加载布局
      showLoading: 1,

      //弹框标题是否居中
      isCenter: true,

      //查看课程的弹框宽度
      LookClassDialogWidth: "800px",

      //学校编码
      schoolNum: "",

      //选择的日期
      chooseTime: "",

      //表格的最大高度
      maxHeight: 0,

      //总的数据条数
      totalSize: 0,

      //条数选择的数据
      pageSizeArr: [6, 8, 10],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //总的数据条数
      stotalSize: 0,

      //当前显示的页码
      scurrPage: 1,

      //总的数据条数
      ctotalSize: 0,

      //当前显示的页码
      ccurrPage: 1,
      //筛选日期
      selectDate: this.getPastTime(1),
      //班级表格数据
      tableData: [],
      //学员表格数据
      stableData: [],
      //课消表格数据
      ctableData: [],
      //是否显示替换老师的弹框
      isShowReplaceTeacherDialog: false,
      //编辑班级
      showEditClassrDialog: false,
      //删除班级
      showDelDialog: false,
      //日期范围
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24 * 366 || time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    //初始化表格的最大高度
    this.initData();
  },
  mounted() {
    //老师ID
    this.tid = this.$route.query.tid;
    this.teacher_item = this.$route.query.teacher_item;

    if (this.isEmpty(this.tid)) {
      //老师角色查看自己
      this.tid = this.getUserInfo().id;
      this.isShowChangeTeacher = false;
    }
    console.log(this.tid);
    //请求页面数据
    this.httpData();

    //班级接口
    this.httpClassData();
  },
  methods: {
    getPastTime(month) {
      let time = new Date();
      const yy = time.getFullYear(); //获取完整的年份(4位,1970-???)
      const M = time.getMonth() + 1; //获取当前月份(0-11,0代表1月),
      const d = time.getDate(); //获取当前日(1-31)

      // 获取指定的过去时间
      const past = M - month;
      const pastM = past < 0 ? past + 12 : past > 9 ? past : "0" + past;
      // 小于9的，在前面加0
      const MM = M > 9 ? M : "0" + M;
      const dd = d > 9 ? d : "0" + d;

      // 指定的过去时间
      const PastTime = yy + "-" + pastM + "-" + dd;
      // 当前时间
      const nowTime = yy + "-" + MM + "-" + dd;

      return [PastTime, nowTime];
    },

    //刷新跳转页面
    updatePage() {
      // this.httpData();

      this.$router.push({
        path: "/Home/TeacherManager",
        query: {},
      });
    },
    changeTeacher() {
      //换老师
      console.log("换老师");
      console.log(this.teacher_item);

      // scope._self.$refs[`popover-${scope.$index}`].doClose();
      //打开替换老师的弹框
      this.isShowReplaceTeacherDialog = true;
    },
    //关闭新增教师弹框
    closeDialog() {
      this.showEditClassrDialog = false;
    },
    //班级/学员查询
    doAction(radio) {
      console.log(radio);
      if (radio == "班级") {
        this.showClassPage = 1;
        this.httpClassData();
      } else if (radio == "学员") {
        this.showClassPage = 2;
        this.httpStudentData();
      } else if (radio == "排课信息") {
        this.showClassPage = 3;
      } else if (radio == "课消记录") {
        this.showClassPage = 4;
        this.httpCourseData();
      }
    },
    //初始化表格数据
    initData() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 76 - -54 - 20 - 68 - 215;
    },
    //课消点击日期
    clickDate() {
      console.log(this.selectDate);
      this.httpCourseData();
    },
    /**
     *  老师信息接口
     */
    httpData() {
      let param = this.getHttpParams();
      param.type = 7;
      param.tid = this.tid;

      this.$http
        .fetchPost(this.$api.TEACHERMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            (this.teacherName = resulte.data.name),
              //手机号
              (this.tel = resulte.data.tel);
            //学校校区
            this.schoolName = resulte.data.school_name;
            //学校ID
            this.schoolId = resulte.data.school_id;
            //开通课程
            this.course = resulte.data.sub_name;
            if (this.isEmpty(this.course)) {
              this.course = "暂无课程权限";
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    /**
     *  班级列表接口
     */
    httpClassData() {
      let param = this.getHttpParams();
      param.type = 8;
      param.tid = this.tid;
      param.page = this.currPage - 1;
      param.pagecount = this.pageSize;

      this.$http
        .fetchPost(this.$api.TEACHERMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            //获取总条数
            this.totalSize = Number.parseInt(resulte.data.totalNum);

            if (this.totalSize != 0) {
              //获取列表数据
              this.tableData = resulte.data.infoList;
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },
    /**
     *  学员列表接口
     */
    httpStudentData() {
      let param = this.getHttpParams();
      param.type = 9;
      param.tid = this.tid;
      param.page = this.scurrPage - 1;
      param.pagecount = this.pageSize;

      this.$http
        .fetchPost(this.$api.TEACHERMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            //获取总条数
            this.stotalSize = Number.parseInt(resulte.data.totalNum);
            if (this.stotalSize != 0) {
              //获取列表数据
              this.stableData = resulte.data.infoList;
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    /**
     *  课消记录列表接口
     */
    httpCourseData() {
      let param = this.getHttpParams();
      param.type = 12;
      param.tid = this.tid;
      param.page = this.scurrPage - 1;
      param.pagecount = this.pageSize;
      param.sDate = this.selectDate[0];
      param.eDate = this.selectDate[1];

      this.$http
        .fetchPost(this.$api.TEACHERMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            //获取总条数
            this.ctotalSize = Number.parseInt(resulte.data.totalNum);
            if (this.ctotalSize != 0) {
              //获取列表数据
              this.ctableData = resulte.data.infoList;
            } else {
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },
    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;

      //重新刷新数据
      this.httpClassData();
    },

    //页面改变时触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currPage = val;

      //重新刷新数据
      this.httpClassData();
    },

    //每页显示的条数变化时触发
    shandleSizeChange(val) {
      this.spageSize = val;

      //重新刷新数据
      this.httpStudentData();
    },

    //页面改变时触发
    shandleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.scurrPage = val;

      //重新刷新数据
      this.httpStudentData();
    },

    //每页显示的条数变化时触发
    chandleSizeChange(val) {
      this.cpageSize = val;

      //重新刷新数据
      this.httpCourseData();
    },

    //页面改变时触发
    chandleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.ccurrPage = val;

      //重新刷新数据
      this.httpCourseData();
    },

    //表格中点击查看的操作
    doLookAction(item) {
      //跳转查看班级

      this.$router.push({
        path: "/Home/ClassDetail",
        query: {
          item: JSON.stringify(item),
        },
      });
    },

    //表格中点击查看的操作
    doLookStudent(item) {
      let param = this.getHttpParams();
      param.type = 17;
      param.student_id = item.id;

      this.$http
        .fetchPost(this.$api.StudentManageController, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            this.$router.push({
              path: "/Home/StuRecord/StudentInfo",
              query: { userInfo: JSON.stringify(resulte.data) },
            });
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //换老师
    doReplaceTeacher(scope) {
      console.log(scope.row);
      scope._self.$refs[`popover-${scope.$index}`].doClose();
      //打开替换老师的弹框
      this.isShowReplaceTeacherDialog = true;
      console.log(item);
    },
    //编辑班级
    doEdit(scope) {
      console.log(scope.row);
      scope._self.$refs[`popover-${scope.$index}`].doClose();
      this.showEditClassrDialog = true;
      console.log(item);
    },
    //复制邀请码
    doCopy(item) {
      console.log(item);

      var temp = document.createElement("textarea");
      temp.value = item.code;
      document.body.appendChild(temp);
      temp.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      temp.style.display = "none";
      this.showSuccessMsg("编码已复制");
    },
    //删除班级
    doDel(scope) {
      console.log(scope.row);
      scope._self.$refs[`popover-${scope.$index}`].doClose();
      this.showDelDialog = true;
    },
  },
  computed: {},
  components: {
    CourseArrangement,
    // ReplaceTeacherDialog,
    EditClassDialog,
    RemoveClassDialog,
    ReplaceTeacherDialog,
  },
};
</script>
<style lang="scss" scoped>
.look_teahcer {
  height: calc(100vh - 77px);
  margin: 0 16px;
  // width: 98%;

  .look_teahcer_content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    ::v-deep .el-table .cell {
      text-align: center;
    }

    .bt_change_teacher {
      color: #409eff;
      cursor: pointer;
    }
    //搜索部分
    .action {
      height: 54px;
      display: flex;
      align-items: center;
      min-width: 1100px;

      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
        padding-left: 6px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input {
        width: 210px;
      }

      ::v-deep .el-input__suffix {
        right: 0px;
      }

      .dv_time {
        display: flex;
        align-items: center;
        ::v-deep .el-range-editor.el-input__inner {
          display: flex;
          align-items: center;
          padding: 0 6px;
          width: 320px;
        }
        ::v-deep {
          .el-date-editor .el-range-input {
            width: 120px;
          }
        }
      }
    }

    //表格内容部分
    .dv_table_view_content {
      // min-width: 1100px;
      margin: 10px 0;

      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
        padding-left: 6px;
      }
      .lineHeight {
        margin: 0px 0 15px 0;
      }
      .f_h {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .normal_size {
        font-weight: normal;
        font-size: 12px;
      }
      //底部的分页器部分
      .bom_page {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        margin-top: 18px;
        display: flex;
        align-items: center;
        height: 40px;
        justify-content: center;
      }
    }
  }
}
</style>
